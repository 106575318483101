import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Modal, message } from 'antd';
import { PlusOutlined, LinkOutlined } from '@ant-design/icons';

import { getFileList, deleteFile } from '../../api';

import FileDetail from './detail';
const fileUrlPrefix = 'https://www.dfsykj.com/srcfile/admin_frontend/other/'

const FilesList = (props) => {
    const tabCols = [
        {
            title: '资料名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '上传时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '是否公开',
            dataIndex: 'isPublic',
            key: 'isPublic',
            render: (text, record) => {
                return record.isPublic == '1' ? '公开':'不公开';
            }
        },
        {
            title: '附件',
            dataIndex: 'link',
            key: 'link',
            render: (text, record) => {
                return (
                    (record.contentSets || []).map(item => {
                        return (
                           <div>
                                <a target='_blank' href={`${fileUrlPrefix}${item.contentUrl}`}>
                                    <LinkOutlined />&nbsp;{item.name}
                                </a>
                           </div>
                        )
                    })
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => {editFile(record)}}>编辑</Button>
                        <Button type='link' onClick={() => {delFile(record.id)}}>删除</Button>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [panelShow, setPanelShow] = useState(false);
    const [curFile, setCurFile] = useState(null);

    useEffect(() => {
        fetchFileList(pageNum, pageSize)
    }, []);

    const fetchFileList = (pNum, pSize) => {
        let params = {pageNum: pNum, pageSize: pSize};
        getFileList(params).then(res => {
            if(res.code == 201){
                setTotal(res.data.total);
                setTabData(res.data.list);
            }
        })
    }

    const pageSizeChangeHandle = (val) => {
        setPageNum(1);
        setPageSize(val);
        fetchFileList(1, val);
    }

    const pageChange = (val) => {
        setPageNum(val);
        fetchFileList(val, pageSize)
    }

    const createNewCase = () =>{
        setCurFile(null);
        setPanelShow(true);
    }

    const delFile = (caseId) => {
        deleteFile(caseId).then(res=>{
            if(res.code == 201){
                message.success('资料已删除', 3);
                fetchFileList(pageNum, pageSize);
            }
        })
    }

    const editFile = (fileItem) => {
        setCurFile(fileItem);
        setPanelShow(true);
    }

    const onModalHide = (needRefresh = false, targetId = null) => {
        setPanelShow(false);
        if(!needRefresh){
            return;
        }
        if(targetId){
            fetchFileList(pageNum, pageSize);
        }
        else{
            setPageNum(1);
            fetchFileList(1, pageSize);
        }
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewCase}>新建资料</Button>
            </div>
            <div className='cnt-item'>
                <Table rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination 
                    total={total} 
                    showTotal={(total) => `共${total}个资料`}
                    current={pageNum} 
                    pageSize={pageSize} 
                    showSizeChanger={true} 
                    pageSizeOptions={[10, 20, 30, 50]}
                    onShowSizeChange={(page,size) => {pageSizeChangeHandle(size);}} 
                    onChange={(page, size) => {pageChange(page);}}
                />
            </div>
            {
                panelShow && (
                    <Modal title='资料编辑' visible={true} width="80vw" footer={null} onCancel={()=>{setPanelShow(false)}}>
                        <FileDetail detail={curFile} onModalHide={onModalHide} />
                    </Modal>
                )
            }
        </div>
    )
}



export default FilesList;