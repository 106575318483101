import React, { useEffect, useRef } from 'react';
import { Descriptions,Input, Select, Button, Form, message } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import 'moment/locale/zh-cn';
import '../../style/main.css';
import {saveCaseInfo} from '../../api';
import PicManager from './picManager';
import {CloudUploadOutlined } from '@ant-design/icons'

const CategorySets = [
    {
        val: 1,
        lab: '文化综合体与城市光影文化'
    },
    {
        val: 2,
        lab: '历史文化街区复兴与夜间经济'
    },
    {
        val: 3,
        lab: '景区提升与文旅小镇开发'
    }
]

const CaseDetail = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    let detail = {}
    if(location.state && location.state.detail){
        detail = JSON.parse(location.state.detail);
    }
    const caseId = parseInt(detail.id || 0);

    const detailInfo = detail;
    const editMode = 1;
    const [picManagerShow, setPicManagerShow] = useState(false);

    const [thumbsUrl, setThumbsUrl] = useState(detail.thumbsUrl || '');
    const [isThumbs, setIsThumbs] = useState(false);

    const [detailUrl, setDetailUrl] = useState(detail.detailUrl || '');
    const [isDetailPic, setIsDetailPic] = useState(false);

    const [name, setName] = useState(detailInfo.name || '');
    const [address, setAddress] = useState(detailInfo.address || '');
    const [shortDesc, setShortDesc] = useState(detailInfo.shortDesc || '');
    const [letters, setLetters] = useState(detailInfo.letters || '');
    const [recommend, setRecommend] = useState(detailInfo.recommend || '');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [category, setCategory] = useState(detailInfo.category || undefined);
    const [videoUrl, setVideoUrl] = useState(detailInfo.videoUrl || '');
    
    const ButtonPart = () => {
        return (<Button type='primary' onClick={() => {setPicManagerShow(true)}}>图片管理</Button>)
    }

    const submitInfo = () => {
        let params = { 
            'name': name, 
            'address': address, 
            'shortDesc': shortDesc, 
            'letters': letters, 
            'recommend': recommend, 
            'videoUrl': videoUrl, 
            'category': (category || 0) 
        };
        params['thumbsUrl'] = thumbsUrl;
        params['detailUrl'] = detailUrl;
        if(caseId > 0){
            params['id'] = caseId;
        }
        
        setSubmitLoading(true);
        saveCaseInfo(params).then(res=>{
            setSubmitLoading(false);
            if(res.code == 201){
                message.success('案例保存成功', 3);
                setTimeout(() => {
                    navigate(-1, {state: {needRefresh: true, targetId: (detailInfo.id ? detailInfo.id:null)}});
                }, 300);
            }
        })
    }

    const onPicManagerHide = () => {
        setPicManagerShow(false);
    }

    const onPicSelected = (url,target) => {
        setPicManagerShow(false)
        let copy = (e)=>{
            e.preventDefault()
            e.clipboardData.setData('text/plain', url)
            message.success('图片链接已复制', 3);
            document.removeEventListener('copy',copy)
        }
        document.addEventListener('copy',copy)
        document.execCommand("Copy");
        if(isThumbs){
            setIsThumbs(false);
            setThumbsUrl(url);
        }
        else if(isDetailPic){
            setIsDetailPic(false);
            setDetailUrl(url);
        }
    }

    const selectThumbsPic = () => {
        setIsThumbs(true);
        setIsDetailPic(false);
        setPicManagerShow(true);
    }

    const selectDetailPic = () => {
        setIsDetailPic(true);
        setIsThumbs(false);
        setPicManagerShow(true);
    }

    return (
        <>
            <div className='main-wrapper'>
                <div style={{flex: 1, width: '100%', overflow: 'scroll'}}>
                    <Form>
                        <Descriptions size='small' title='案例内容' column={2} style={{margin: '15px 10px'}} layout='vertical' bordered extra={ButtonPart()}>
                            <Descriptions.Item label="案例名称" span={1}>
                                {
                                    editMode == 1 ?
                                    <Input name='name' onChange={(evt) => {setName(evt.target.value)}} defaultValue={detailInfo.name} placeholder='名称' style={{maxWidth: '300px'}} />
                                    :
                                    <>{detailInfo.name}</>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="所属类别" span={1}>
                                {
                                    <Select defaultValue={detailInfo.category} placeholder='请选择案例类别' style={{width: '300px'}} onChange={(value) => {setCategory(value)}}>
                                        {
                                            CategorySets.map(item => {
                                                return <>
                                                    <Select.Option key={item.val} value={item.val}>{item.lab}</Select.Option>
                                                </>
                                            })
                                        }
                                    </Select>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="特色简介" span={1}>
                                {
                                    editMode == 1 ?
                                    <Input name='shortDesc' onChange={(evt) => {setShortDesc(evt.target.value)}} defaultValue={detailInfo.shortDesc} placeholder='简介，20个字以内' style={{maxWidth: '300px'}} />
                                    :
                                    <>{detailInfo.shortDesc}</>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="所在省市" span={1}>
                                {
                                    editMode == 1 ?
                                    <Input name='address' onChange={(evt) => {setAddress(evt.target.value)}} defaultValue={detailInfo.address} placeholder='所在省市' style={{maxWidth: '300px'}} />
                                    :
                                    <>{detailInfo.address}</>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="所在省市汉语拼音" span={1}>
                                {
                                    editMode == 1 ?
                                    <Input name='letters' onChange={(evt) => {setLetters(evt.target.value)}} defaultValue={detailInfo.letters} placeholder='大写字母拼音' style={{maxWidth: '300px'}} />
                                    :
                                    <>{detailInfo.letters}</>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="推荐度" span={1}>
                                {
                                    editMode == 1 ?
                                    <Input name='recommend' onChange={(evt) => {setRecommend(evt.target.value)}} defaultValue={detailInfo.recommend} placeholder='推荐度' style={{maxWidth: '300px'}} />
                                    :
                                    <>{detailInfo.recommend}</>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="推荐图" span={1}>
                                {
                                    editMode == 1 ?
                                    (
                                        <div style={{color: '#108ee9', cursor: 'pointer'}} onClick={() => {selectThumbsPic()}}>
                                            {
                                                thumbsUrl 
                                                ?
                                                <img src={`${thumbsUrl}`} style={{maxWidth: '180px', maxHeight: '120px'}} />
                                                :
                                                <div style={{padding: '10px', fontSize: '30px', textAlign: 'center'}}><CloudUploadOutlined size={50} /><div style={{fontSize: '14px'}}>去上传</div></div>
                                            } 
                                        </div>
                                    ):
                                    <img src={`${thumbsUrl}`} style={{maxWidth: '180px', maxHeight: '120px'}} />
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="详情图" span={1}>
                                {
                                    editMode == 1 ?
                                    (
                                        <div style={{color: '#108ee9', cursor: 'pointer'}} onClick={() => {selectDetailPic()}}>
                                            {
                                                detailUrl 
                                                ?
                                                <img src={`${detailUrl}`} style={{maxWidth: '180px', maxHeight: '120px'}} />
                                                :
                                                <div style={{padding: '10px', fontSize: '30px', textAlign: 'center'}}><CloudUploadOutlined size={50} /><div style={{fontSize: '14px'}}>去上传</div></div>
                                            } 
                                        </div>
                                    ):
                                    <img src={`${detailUrl}`} style={{maxWidth: '180px', maxHeight: '120px'}} />
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="视频链接" span={2}>
                                {
                                    editMode == 1 ?
                                    <Input name='videoUrl' onChange={(evt) => {setVideoUrl(evt.target.value)}} defaultValue={detailInfo.videoUrl} placeholder='七牛云视频链接' style={{maxWidth: '700px'}} />
                                    :
                                    <>{detailInfo.videoUrl}</>
                                }
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <div className='item-flex-row-start' style={{marginTop: '20px', paddingBottom: '10px', marginLeft: '10px'}}>
                        <Button type='primary' loading={submitLoading} onClick={submitInfo}>保存</Button>
                        <Button style={{marginLeft: '10px'}} type='default' onClick={() => { navigate(-1) }}>取消</Button>
                    </div>
                </div>
                {
                    picManagerShow ? <PicManager onHide={onPicManagerHide} onSelected={onPicSelected} />:null
                }
            </div>
        </>
    )
}

export default CaseDetail;