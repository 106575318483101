import React from "react";
import {Layout, Menu } from 'antd';
import {AppstoreOutlined, GlobalOutlined, UserOutlined, KeyOutlined, TeamOutlined} from "@ant-design/icons";
import '../style/layout.css';
import {useLocation, useNavigate} from 'react-router-dom';
import { MainLayoutUrl } from '../router';

const { Header, Content, Footer, Sider } = Layout;

const MainLayout = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { children } = props;

    const menuChangeHandle = (evt) => {
        navigate(MainLayoutUrl[evt.key].index.path);
    }

    const defaultKey = Object.keys(MainLayoutUrl).filter(item => location.pathname.indexOf(item) >= 0)[0];

    return (
        <Layout style={{height: '100vh'}}>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="inc-header">
                    大丰·云管理平台
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[defaultKey]} onClick={menuChangeHandle}>
                    <Menu.Item key="cases" icon={<AppstoreOutlined />}>
                        案例管理
                    </Menu.Item>
                    <Menu.Item key="files" icon={<GlobalOutlined />}>
                        资料管理
                    </Menu.Item>
                    <Menu.Item key="auth" icon={<UserOutlined />}>
                        授权管理
                    </Menu.Item>
                    <Menu.Item key="password" icon={<KeyOutlined />}>
                        临时口令
                    </Menu.Item>
                    <Menu.Item key="tempclients" icon={<TeamOutlined />}>
                        游客访问
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
                    header
                </Header>
                <Content style={{ margin: '24px 16px 0', overflowY: 'scroll' }} className="site-layout-background">
                    { children }
                </Content>
                <Footer style={{ textAlign: 'center' }}>Da Feng ©2022 Created by Scp</Footer>
            </Layout>
        </Layout>
    )
}

export default MainLayout;