import React, {useState} from "react";
import '../../style/login.css';
import { Button, Input } from "antd";
import {KeyOutlined,UserOutlined} from '@ant-design/icons';
import { Navigate } from 'react-router-dom';
import { getQueryString } from '../../utils/comm';
import { setCookie } from '../../utils/cookie';
import { loginAction } from '../../api';

const LoginPanel = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [needRedirect, setNeedRedirect] = useState(false);

    const redirectPath = getQueryString('redirect');

    const usernameInput = (evt) => {
        setUsername(evt.target.value);
    }

    const passwordInput = (evt) => {
        setPassword(evt.target.value);
    }

    const submitHandle = ()=>{
        let params = { username, password };
        loginAction(params).then(res => {
            if(res.code == 201){
                setCookie('user-token', res.token);
                setNeedRedirect(true);
            }
        })
    }

    return (
        <div className="main">
            <div className="login-form">
                <h1>大丰·云管理平台</h1>
                <div className="head">
                    <img src='/images/logo.jpg' alt="" />
                </div>
                <form>
                    <div className="username">
                        <Input type='text' placeholder="用户名" prefix={<UserOutlined />} size='large' onChange={evt => {usernameInput(evt);}} />
                    </div>
                    <div className="password">
                        <Input.Password placeholder="密码" prefix={<KeyOutlined />} size='large' onChange={evt => {passwordInput(evt);}} />
                    </div>
                    <div className="submit">
                        <Button type="primary" block={true} size='large' onClick={submitHandle}>登 录</Button>
                    </div>
                </form>
            </div>
            {
                needRedirect ? <Navigate to={`/${redirectPath ? redirectPath:''}`} props={{account:{name: username, pwd: password}}} replace={true} /> : null
            }
        </div>
    );
}

export default LoginPanel;