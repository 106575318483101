import {useEffect, useState} from "react";
import {temporaryAuth} from '../../api';
import {message, Descriptions, Button} from 'antd';

const TemporaryPwd = (props) => {
    const [pwd, setPwd] = useState('');
    useEffect(() => {
        getTempPwd();
    }, []);

    const getTempPwd = () => {
        let params = {flag: 'pwd'};
        temporaryAuth(params).then(res => {
            if(res.code == 201){
                setPwd(res.data.pwd);
            }
        })
    }

    const refreshTempPwd = () => {
        let params = {flag: 'refresh'};
        temporaryAuth(params).then(res => {
            if(res.code == 201){
                message.success('临时访问口令已刷新', 3);
                setPwd(res.data.pwd);
            }
        })
    }

    return (
        <div className="main-wrapper">
            <div style={{'margin': '20px'}}>
                <Descriptions title="小程序临时访问口令" column={3} layout={'vertical'} bordered extra={<Button type={'link'} style={{'marginLeft': '10px'}} onClick={refreshTempPwd}>刷新口令</Button>}>
                    <Descriptions.Item label='口令' span={2}>
                        <div style={{'display': 'flex', 'justifyContent': 'flex-start'}}>
                            {
                                pwd.split('').map((code, index) => {
                                    return (<div key={index} style={{'fontSize': '30px', 'fontWeight': 'bold', 'background': 'black', 'color': 'white', 'width': '60px', 'height': '80px', 'textAlign': 'center', 'lineHeight': '80px', 'marginRight': '20px'}}>
                                        {code}
                                    </div>)
                                })
                            }
                        </div>
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    )
}

export default TemporaryPwd;