import React, { useEffect, useState } from "react";
import {Modal, Upload, Pagination, Button, Result, Image} from 'antd';
import cookie from "../../utils/cookie";
import {PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { getUploadDatas, deleteUploadPic } from '../../api';
import '../../style/main.css';

const uploadUrl = 'https://www.dfsykj.com/admin/upload';
const imgUrlPrefix = 'https://www.dfsykj.com/srcfile/admin_frontend/imgs/'

const PicManager = (props) => {
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [imgslist, setImgslist] = useState([]);
    const [uploadShow, setUploadShow] = useState(false);
    const [uploadFileList, setUploadFileList] = useState([]);
    const [curSelectedPic, setCurrSelectedPic] = useState(0);
    const [previewShow, setPreviewShow] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fetchList = (pNum, pSize) => {
        let param = {
            'pageNum': pNum,
            'pageSize': pSize,
            'fileType': 'imgs'
        }
        getUploadDatas(param).then(res=>{
            if(res.code == 201){
                setImgslist(res.data.list);
                setTotal(res.data.total);
            }
        })
    }

    const paginationChange = (pNum, pSize) => {
        setPageNum(pNum);
        setPageSize(pSize);
        fetchList(pNum, pSize);
    }

    const uploadModalHide = () => {
        setUploadShow(false);
        setUploadFileList([])
        setPageNum(1);
        fetchList(1, pageSize);
    }
    const imgPreview = (imgName) => {
        setPreviewUrl(`${imgUrlPrefix}${imgName}`);
        setPreviewShow(true);
    }
    const imgDelete = (picId) => {
        deleteUploadPic(picId).then(res=>{
            if(res.code == 201){
                fetchList(pageNum, pageSize);
            }
        })
    }

    const selectedHandle = (picId) => {
        setCurrSelectedPic(picId);
        setTimeout(() => {
            let pic = `${imgUrlPrefix}${imgslist.filter(item => item.id == picId)[0].name}`
            props.onSelected(pic, null);
        }, 500);
    }

    useEffect(() => {
        fetchList(pageNum, pageSize);
    }, [])
    return (
        <div>
            <Modal title="图片管理中心" visible={true} width="60vw" onCancel={props.onHide} footer={null}>
                <div className="pic-list item-flex-row-start">
                    {
                        imgslist.map( (item, index) => {
                            return (
                                <div className="pic-item" key={item.id}>
                                    <img src={`${imgUrlPrefix}${item.name}`} onClick={() => {selectedHandle(item.id)}} />
                                    <div className="bar item-flex-row">
                                        <div style={{fontSize: '12px', flex: 1, marginRight: '20px'}}>
                                            <b style={{fontSize: '14px'}}>No.{(index + 1) + (pageNum - 1) * pageSize}</b>
                                            &nbsp;{item.name}
                                        </div>
                                        <div>
                                            <EyeOutlined size={24} onClick={() => {imgPreview(item.name)}} />
                                            <DeleteOutlined style={{marginLeft: '10px'}} size={24} onClick={() => {imgDelete(item.id)}} />
                                        </div>
                                    </div>
                                    <div className="selected" style={{width: (curSelectedPic == item.id ? '100%':0)}}>已选择</div>
                                </div>
                            )
                        })
                    }
                    {
                        imgslist.length <= 0 ? (
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <Result status={'info'} title="暂无图片，请去上传" />
                            </div>
                        ):null
                    }
                </div>
                <div className="item-flex-row">
                    <Button type="primary" onClick={()=>{setUploadShow(true)}}>去上传</Button>
                    <Pagination showTotal={(total) => `共${total}张图片`} total={total} onChange={(pNum,pSize) => paginationChange(pNum, pSize)} pageSize={pageSize} current={pageNum} />
                </div>
            </Modal>
            {
                uploadShow && (
                    <Modal visible={true} title="图片上传" width="50vw" onCancel={uploadModalHide} footer={null}>
                        <Upload 
                            action={uploadUrl}
                            headers={{'Authorization': `Bearer ${cookie.getCookie('user-token')}`}}
                            listType={'picture-card'} 
                            data={{'fileType': 'imgs'}}
                            defaultFileList={uploadFileList}
                            multiple={true} 
                            showUploadList={{showRemoveIcon: false, showPreviewIcon: false}}>
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>上传</div>
                            </div>
                        </Upload>
                    </Modal>
                )
            }
            <Image
                width={200}
                style={{ display: 'none' }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                preview={{
                    previewShow,
                    src: previewUrl,
                    onVisibleChange: value => {
                        setPreviewShow(value);
                    },
                }}
            />
        </div>
    )
}

export default PicManager;