import React, {useState} from "react";
import {Route, BrowserRouter as Router, Routes, useLocation, Navigate } from 'react-router-dom';
import LoginPanel from "../components/login";
import MainLayout from "../layout/mainlayout";
import CaseList from '../components/cases';
import CaseDetail from "../components/cases/detail";
import FilesList from "../components/files";
import FilesDetail from "../components/files/detail";
import AuthCenter from "../components/auth/index";
import TemporaryPwd from "../components/temporary/pwd";
import TemporaryAuthRecord from "../components/temporary/record";
import { getCookie } from '../utils/cookie';


export const MainLayoutUrl = {
    cases: {
        index: {
            path: '/cases',
            selectedKey: 'cases'
        },
        item: {
            path: '/cases/:id',
            selectedKey: 'cases'
        }
    },
    files: {
        index: {
            path: '/files',
            selectedKey: 'files'
        },
        item: {
            path: '/files/:id',
            selectedKey: 'files'
        }
    },
    auth: {
        index: {
            path: '/auth',
            selectedKey: 'auth'
        }
    },
    password: {
        index: {
            path: '/pwd',
            selectedKey: 'password'
        }
    },
    tempclients: {
        index: {
            path: '/tempclients',
            selectedKey: 'tempclients'
        }
    }
};

const MainRouters =  [
    {path: MainLayoutUrl.cases.index.path, key: 'cases', exact: true, element: <CaseList />},
    {path: MainLayoutUrl.cases.item.path, key: 'casesItem', exact: true, element: <CaseDetail />},
    {path: MainLayoutUrl.files.index.path, key: 'files', exact: true, element: <FilesList />},
    {path: MainLayoutUrl.files.item.path, key: 'filesItem', exact: true, element: <FilesDetail />},
    {path: MainLayoutUrl.auth.index.path, key: 'auth', exact: true, element: <AuthCenter />},
    {path: MainLayoutUrl.password.index.path, key: 'password', exact: true, element: <TemporaryPwd />},
    {path: MainLayoutUrl.tempclients.index.path, key: 'tempclients', exact: true, element: <TemporaryAuthRecord />}
];

const SystemRouters = () => {
    return (
        <Router>
            <Routes>
                <Route path='/login' exact={true} element={ <LoginRoute><LoginPanel /></LoginRoute> } />
                {
                    MainRouters.map( route => {
                        return (
                            <Route 
                                key={route.key} 
                                path={route.path} 
                                exact={route.exact} 
                                element={ <PrivateRoute><MainLayout children={route.element} /></PrivateRoute>} 
                            />
                        );
                    })
                }
                <Route path="*" element={ <Navigate to='/cases' replace={true} />} />
            </Routes>
        </Router>
    );
}

const LoginRoute = (props) => {
    const { children } = props;
    const isLoggedIn = (getCookie('user-token') ? true:false);
    if(!isLoggedIn){
        return ( <>{children}</> );
    }
    return <Navigate replace={true} to="/"/>
}

const PrivateRoute = (props) => {
    const { children } = props;
    const isLoggedIn = (getCookie('user-token') ? true:false);
    const location = useLocation();
    if(isLoggedIn){
        return ( <>{children}</> );
    }
    return <Navigate replace={true} to="/login" state={{ from: `${location.pathname}${location.search}` }} />
}

export default SystemRouters;