import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Pagination, message, Upload, Input, Tabs } from "antd";
import { PlusOutlined, CloudUploadOutlined, SearchOutlined } from '@ant-design/icons';
import { getAuthList, deleteAuth } from '../../api';
import cookie from '../../utils/cookie';

import AuthPanel from "./detail";
const uploadUrl = 'https://www.dfsykj.com/admin/upload';

const AuthCenter = (props) => {
    const tabCols = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '职位',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: '授权状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return record.status == '1' ? '已激活':'未激活'
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => {editAuthAction(record)}}>编辑</Button>
                        <Button type='link' onClick={() => {deleteAuthAction(record.id)}}>删除</Button>
                    </Button.Group>
                )
            }
        }
    ]
    const [panelShow, setPanelShow] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [keywords, setKeywords] = useState('');
    const [total, setTotal] = useState(0);
    const [tabData, setTabData] = useState([]);
    const [curUser, setCurUser] = useState(null);
    const [curDataType, setCurDataType] = useState('1');

    const createNewAuth = () => {
        setCurUser(null);
        setPanelShow(true);
    }

    const editAuthAction = (user) => {
        setCurUser(user);
        setPanelShow(true);
    }

    const deleteAuthAction = (userId) => {
        deleteAuth(userId, curDataType).then(res=>{
            if(res.code == 201){
                message.success('授权用户已删除', 3);
                fetchAuthList(pageNum, pageSize, curDataType);
            }
        })
    }

    useEffect(() => {
        fetchAuthList(pageNum, pageSize, curDataType)
    }, []);

    const fetchAuthList = (pNum, pSize, dataType) => {
        let params = {pageNum: pNum, pageSize: pSize, keywords: keywords, type: parseInt(dataType)};
        getAuthList(params).then(res => {
            if(res.code == 201){
                setTotal(res.data.total);
                setTabData(res.data.list);
            }
        })
    }

    const onModalHide = (needRefresh = false, targetId = null) => {
        setPanelShow(false);
        if(!needRefresh){
            return;
        }
        if(targetId){
            fetchAuthList(pageNum, pageSize, curDataType);
        }
        else{
            setPageNum(1);
            fetchAuthList(1, pageSize, curDataType);
        }
    }

    const pageSizeChangeHandle = (val) => {
        setPageNum(1);
        setPageSize(val);
        fetchAuthList(1, val, curDataType);
    }

    const pageChange = (val) => {
        setPageNum(val);
        fetchAuthList(val, pageSize, curDataType)
    }
    
    const startSearch = () => {
        setPageNum(1);
        fetchAuthList(1, pageSize, curDataType);
    }

    const uploadChange = (info) => {
        if(info.file.status === 'done'){
            message.success('批量上传已完成', 3);
            setPageNum(1);
            fetchAuthList(1, pageSize, curDataType);
        }
        else if(info.file.status === 'error'){
            message.error('上传失败，请检查文件内容或格式', 3);
        }
    }

    const onTabChange = (value) => {
        setCurDataType(value);
        setPageNum(1);
        fetchAuthList(1, pageSize, value);
    }

    return (
        <div className='main-wrapper'>
                <div className='top-part cnt-item' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex'}}>
                        <Input placeholder="搜索姓名或电话" style={{width: '250px'}} onChange={(evt) => {setKeywords(evt.target.value)}} allowClear onPressEnter={startSearch} />
                        <Button type='primary' style={{marginLeft: '10px'}} icon={<SearchOutlined />} onClick={startSearch}>查询</Button>
                    </div>
                    <div>
                        <Button type='primary' style={{marginRight: '10px'}} icon={<PlusOutlined />} onClick={createNewAuth}>创建授权</Button>
                        <Upload action={uploadUrl}
                                onChange={uploadChange}
                                headers={{'Authorization': `Bearer ${cookie.getCookie('user-token')}`}}
                                data={{'fileType': 'auth'}}
                                showUploadList={false}>
                            <Button type="primary" style={{marginRight: '10px'}} icon={<CloudUploadOutlined />} >批量授权领导</Button>
                        </Upload>
                        <Upload action={uploadUrl}
                                onChange={uploadChange}
                                headers={{'Authorization': `Bearer ${cookie.getCookie('user-token')}`}}
                                data={{'fileType': 'authEmp'}}
                                showUploadList={false}>
                            <Button type="primary" icon={<CloudUploadOutlined />} >批量授权员工</Button>
                        </Upload>
                    </div>
                </div>
                <div className='cnt-item'>
                    <Tabs activeKey={curDataType} onChange={onTabChange} style={{marginLeft: '10px'}}>
                        <Tabs.TabPane tab="领导授权名单" key={'1'}></Tabs.TabPane>
                        <Tabs.TabPane tab="员工授权名单" key={'2'}></Tabs.TabPane>
                    </Tabs>
                    <Table rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
                </div>
                <div className='footer-part cnt-item'>
                    <Pagination 
                        total={total} 
                        showTotal={(total) => `共${total}个授权用户`}
                        current={pageNum} 
                        pageSize={pageSize} 
                        showSizeChanger={true} 
                        pageSizeOptions={[10, 20, 30, 50]}
                        onShowSizeChange={(page,size) => {pageSizeChangeHandle(size);}} 
                        onChange={(page, size) => {pageChange(page);}}
                    />
                </div>
            {
                panelShow && (
                    <Modal title='授权编辑' visible={true} width="80vw" footer={null} onCancel={()=>{setPanelShow(false)}}>
                        <AuthPanel dataType={curDataType} detail={curUser} onModalHide={onModalHide} />
                    </Modal>
                )
            }
        </div>
    )
}

export default AuthCenter;