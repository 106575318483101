import { useEffect, useState } from "react";
import {temporaryAuth} from '../../api';
import {Table, Pagination} from 'antd'

const tabCols = [
    {
        title: '申请访问时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: '微信昵称',
        dataIndex: 'nick',
        key: 'nick',
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
    }
]

const TemporaryAuthRecord = (props) => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchTemporaryAuthList(pageNum, pageSize);
    }, [])

    const fetchTemporaryAuthList = (pNum, pSize) => {
        let params = {
            pageSize: pSize,
            pageNum: pNum,
            flag: 'record'
        };
        temporaryAuth(params).then(res => {
            if(res.code == 201){
                setTotal(res.data.total);
                setTabData(res.data.list);
            }
        })
    }

    const pageSizeChangeHandle = (val) => {
        setPageNum(1);
        setPageSize(val);
        fetchTemporaryAuthList(1, val);
    }

    const pageChange = (val) => {
        setPageNum(val);
        fetchTemporaryAuthList(val, pageSize)
    }

    return (
        <div className="main-wrapper" style={{display: 'block'}}>
                <div className='cnt-item'>
                    <Table rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
                </div>
                <div className='footer-part cnt-item'>
                    <Pagination 
                        total={total} 
                        showTotal={(total) => `共${total}个授权用户`}
                        current={pageNum} 
                        pageSize={pageSize} 
                        showSizeChanger={true} 
                        pageSizeOptions={[10, 20, 30, 50]}
                        onShowSizeChange={(page,size) => {pageSizeChangeHandle(size);}} 
                        onChange={(page, size) => {pageChange(page);}}
                    />
                </div>
        </div>
    )
}

export default TemporaryAuthRecord;