import React from 'react';
import { Descriptions,Input, Button, Form, message, Tag, Select } from 'antd';
import { useState } from 'react';
import '../../style/main.css';
import {saveAuth} from '../../api';

const AuthPanel = (props) => {
    let detail = {}
    if(props.detail){
        detail = props.detail;
    }
    const userId = parseInt(detail.id || 0);

    const detailInfo = detail;

    const [name, setName] = useState(detailInfo.name || '');
    const [status, setStatus] = useState(detailInfo.status || '');
    const [role, setRole] = useState(detailInfo.role || '');
    const [mobile, setMobile] = useState(detailInfo.mobile || '');
    const [submitLoading, setSubmitLoading] = useState(false);

    const submitInfo = () => {
        let params = { name: name, status: status, mobile: mobile, role: role, type: props.dataType};
        if(userId > 0){
            params['id'] = userId;
        }
        setSubmitLoading(true);
        saveAuth(params).then(res=>{
            setSubmitLoading(false);
            if(res.code == 201){
                message.success('资料保存成功', 3);
                setTimeout(() => {
                    props.onModalHide(true, (detailInfo.id ? detailInfo.id:null))
                }, 300);
            }
        })
    }

    return (
        <>
            <div className='main-wrapper'>
                <div style={{flex: 1, width: '100%', overflow: 'scroll'}}>
                    <Form>
                        <Descriptions size='small' title='授权用户身份信息' column={2} style={{margin: '15px 10px'}} layout='vertical' bordered>
                            <Descriptions.Item label="姓名" span={1}>
                                <Input name='name' onChange={(evt) => {setName(evt.target.value)}} defaultValue={detailInfo.name} placeholder='名称' style={{maxWidth: '300px'}} />
                            </Descriptions.Item>
                            <Descriptions.Item label="手机号" span={1}>
                                <Input name='mobile' onChange={(evt) => {setMobile(evt.target.value)}} defaultValue={detailInfo.mobile} placeholder='手机号' style={{maxWidth: '300px'}} />
                            </Descriptions.Item>
                            <Descriptions.Item label="职位" span={1}>
                                <Input name='role' onChange={(evt) => {setRole(evt.target.value)}} defaultValue={detailInfo.role} placeholder='职位' style={{maxWidth: '300px'}} />
                            </Descriptions.Item>
                            <Descriptions.Item label="是否激活授权" span={1}>
                                <Select defaultValue={detailInfo.status} placeholder='是否激活授权' style={{width: '300px'}} onChange={(value) => {setStatus(value)}}>
                                    <Select.Option value={'1'}>激活</Select.Option>
                                    <Select.Option value={'0'}>未激活</Select.Option>
                                </Select>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <div className='item-flex-row-start' style={{marginTop: '20px', paddingBottom: '10px', marginLeft: '10px'}}>
                        <Button type='primary' loading={submitLoading} onClick={submitInfo}>保存</Button>
                        <Button style={{marginLeft: '10px'}} type='default' onClick={() => {props.onModalHide()}}>取消</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthPanel;