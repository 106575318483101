import React, { useEffect } from 'react';
import { Descriptions,Input, Button, Form, message, Tag, Select } from 'antd';
import { useState } from 'react';
import '../../style/main.css';
import {updateFile} from '../../api';
import FileManager from './fileManager';
import {LinkOutlined, CloudUploadOutlined } from '@ant-design/icons'

const FileDetail = (props) => {
    let detail = {}
    if(props.detail){
        detail = props.detail;
    }
    const fileId = parseInt(detail.id || 0);

    const detailInfo = detail;
    const [fileManagerShow, setFileManagerShow] = useState(false);
    const [fileUrls, setFileUrls] = useState((detail.contentSets || []));

    const [name, setName] = useState(detailInfo.name || '');
    const [submitLoading, setSubmitLoading] = useState(false);

    const [isPublic, setIsPublic] = useState(detailInfo.isPublic || '0');

    const ButtonPart = () => {
        return (<Button type='primary' onClick={() => {setFileManagerShow(true)}}>文件管理</Button>)
    }

    const submitInfo = () => {
        let params = { name, isPublic: isPublic, contentUrl: fileUrls.map(item => item.id).join(',') };
        if(fileId > 0){
            params['id'] = fileId;
        }
        setSubmitLoading(true);
        updateFile(params).then(res=>{
            setSubmitLoading(false);
            if(res.code == 201){
                message.success('资料保存成功', 3);
                setTimeout(() => {
                    props.onModalHide(true, (detailInfo.id ? detailInfo.id:null))
                }, 300);
            }
        })
    }

    const onFileManagerHide = () => {
        setFileManagerShow(false);
    }

    const onFileSelected = (url) => {
        setFileManagerShow(false)
        let urls = [...fileUrls];
        if(urls.filter(item => item.id == url.id).length <= 0){
            setFileUrls(urls.concat([url]));
        }
        else{
            message.warning('附件已存在');
        }
    }

    const selectFile = () => {
        setFileManagerShow(true);
    }

    const deleteFileLink = (fileId) => {
        if(fileId <= 0){
            setFileUrls(fileUrls.filter(item => item.id != fileId));
            return;
        }
        let contentIds = fileUrls.map(item => item.id).filter(id => id != fileId);
        let params = {
            name, isPublic: isPublic, contentUrl: contentIds.join(',')
        };
        params['id'] = fileId;
        setSubmitLoading(true);
        updateFile(params).then(res=>{
            setSubmitLoading(false);
            setFileUrls(fileUrls.filter(item => item.id != fileId));
        })
    }

    return (
        <>
            <div className='main-wrapper'>
                <div style={{flex: 1, width: '100%', overflow: 'scroll'}}>
                    <Form>
                        <Descriptions size='small' title='资料内容' column={2} style={{margin: '15px 10px'}} layout='vertical' bordered extra={ButtonPart()}>
                            <Descriptions.Item label="资料名称" span={1}>
                                <Input name='name' onChange={(evt) => {setName(evt.target.value)}} defaultValue={detailInfo.name} placeholder='名称' style={{maxWidth: '300px'}} />
                            </Descriptions.Item>
                            <Descriptions.Item label="是否公开" span={1}>
                                <Select defaultValue={detailInfo.isPublic} placeholder='资料是否公开' style={{width: '300px'}} onChange={(value) => {setIsPublic(value)}}>
                                    <Select.Option value={'1'}>公开</Select.Option>
                                    <Select.Option value={'0'}>不公开</Select.Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="附件" span={1}>
                                {
                                    fileUrls.map(item => {
                                        return (
                                            <div>
                                                <Tag color='#108ee9' style={{color: 'white', cursor: 'pointer'}} closable onClose={()=>{deleteFileLink(item.id)}} >
                                                    <LinkOutlined />&nbsp;
                                                    {item.name}
                                                </Tag>
                                            </div>
                                        )
                                    })
                                }
                                <Tag style={{cursor: 'pointer'}} onClick={()=>{selectFile()}}>
                                    <CloudUploadOutlined />
                                    &nbsp;去上传
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                    <div className='item-flex-row-start' style={{marginTop: '20px', paddingBottom: '10px', marginLeft: '10px'}}>
                        <Button type='primary' loading={submitLoading} onClick={submitInfo}>保存</Button>
                        <Button style={{marginLeft: '10px'}} type='default' onClick={() => {props.onModalHide()}}>取消</Button>
                    </div>
                </div>
                {
                    fileManagerShow ? <FileManager onHide={onFileManagerHide} onSelected={onFileSelected} />:null
                }
            </div>
        </>
    )
}

export default FileDetail;