import cookie from '../utils/cookie';
import {message} from 'antd';
import axios from 'axios';

const BASE_URL = "https://www.dfsykj.com/";

function post(url,params){
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if(token){
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.post(BASE_URL + url, params,{headers,timeout: 60000}).then(response => {
            const res = response.data;
            if(response.status == 200){
                if(res.code != 201){
                    message.warning(res.reason || res.msg,2)
                    resolve(false);
                }
                else{
                    resolve(res);
                }
            }
            else{
                message.warning(response.status + '错误',2)
            }
        })
    })
}

function get(url,params){
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if(token){
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.get(BASE_URL + url,{params,headers},{timeout: 60000}).then(response => {
            if(response.status == 200){
                const res = response.data;
                if(res.code != 201){
                    if(res.code == 102){
                        cookie.deleteCookie('user-token');
                        window.location.reload();
                    }
                    else{
                        message.warning(res.reason || res.msg,2)
                        resolve(false);
                    }
                }
                else{
                    resolve(res);
                }
            }
            else{
                message.warning(response.status + '错误',2)
            }
        })
    })
}

function patch(url,params){
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/json',
        }
        let token = cookie.getCookie('jwt-app');
        if(token){
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.patch(BASE_URL + url,params, {headers:headers},{timeout: 60000}).then(response => {
            if(response.status == 200){
                const res = response.data;
                if(res.code != 201){
                    message.warning(res.reason || res.msg,2)
                    resolve(false);
                }
                else{
                    resolve(res);
                }
            }
            else{
                message.warning(response.status + '错误',2)
            }
        })
    })
}

function dataDel(url){
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if(token){
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.delete(BASE_URL + url,{params:{},headers:headers,timeout:60000}).then(response => {
            if(response.status == 200){
                const res = response.data;
                if(res.code != 201){
                    message.warning(res.reason || res.msg,2)
                    resolve(false);
                }
                else{
                    resolve(res);
                }
            }
            else{
                message.warning(response.status + '错误',2)
            }
        })
    })
}

export default {
    post,
    get,
    patch,
    dataDel
}
