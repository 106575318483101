import React, { useEffect, useState } from "react";
import {Modal, Upload, Pagination, Button, Result } from 'antd';
import cookie from "../../utils/cookie";
import {DeleteOutlined, EyeOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { getUploadDatas, deleteUploadFile } from '../../api';
import '../../style/main.css';

const uploadUrl = 'https://www.dfsykj.com/admin/upload';
const fileUrlPrefix = 'https://www.dfsykj.com/srcfile/admin_frontend/other/'

const FileManager = (props) => {
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [filesList, setFilesList] = useState([]);
    const [uploadShow, setUploadShow] = useState(false);
    const [uploadFileList, setUploadFileList] = useState([]);
    const [curSelectedFile, setCurrSelectedFile] = useState(0);

    const fetchList = (pNum, pSize) => {
        let param = {
            'pageNum': pNum,
            'pageSize': pSize,
            'fileType': 'other'
        }
        getUploadDatas(param).then(res=>{
            if(res.code == 201){
                setFilesList(res.data.list);
                setTotal(res.data.total);
            }
        })
    }

    const paginationChange = (pNum, pSize) => {
        setPageNum(pNum);
        setPageSize(pSize);
        fetchList(pNum, pSize);
    }

    const uploadModalHide = () => {
        setUploadShow(false);
        setUploadFileList([])
        setPageNum(1);
        fetchList(1, pageSize);
    }
    const filePreview = (fileUrl) => {
        window.open(fileUrl, '_blank');
    }
    const fileDelete = (fileId) => {
        deleteUploadFile(fileId, 'other').then(res=>{
            if(res.code == 201){
                fetchList(pageNum, pageSize);
            }
        })
    }

    const selectedHandle = (fileItem) => {
        setCurrSelectedFile(fileItem.id);
        setTimeout(() => {
            props.onSelected(fileItem);
        }, 500);
    }

    useEffect(() => {
        fetchList(pageNum, pageSize);
    }, [])
    return (
        <div>
            <Modal title="文件管理中心" visible={true} width="60vw" onCancel={props.onHide} footer={null}>
                <div className="pic-list item-flex-row-start">
                    {
                        filesList.map( (item, index) => {
                            return (
                                <div className="pic-item" key={item.id}>
                                    <div className="item-flex-row-center" style={{width: '100px', height: '100px', fontSize: '14px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {selectedHandle(item)}}>{item.name}</div>
                                    <div className="bar item-flex-row">
                                        <div style={{fontSize: '14px'}}><b>No.{(index + 1) + (pageNum - 1) * pageSize}</b></div>
                                        <div>
                                            <EyeOutlined size={24} onClick={() => {filePreview(`${fileUrlPrefix}${item.contentUrl}`)}} />
                                            <DeleteOutlined style={{marginLeft: '10px'}} size={24} onClick={() => {fileDelete(item.id)}} />
                                        </div>
                                    </div>
                                    <div className="selected" style={{width: (curSelectedFile == item.id ? '100%':0)}}>已选择</div>
                                </div>
                            )
                        })
                    }
                    {
                        filesList.length <= 0 ? (
                            <div style={{flex: 1, display: 'flex', justifyContent: 'center'}}>
                                <Result status={'info'} title="暂无文件，请去上传" />
                            </div>
                        ):null
                    }
                </div>
                <div className="item-flex-row">
                    <Button type="primary" onClick={()=>{setUploadShow(true)}}>去上传</Button>
                    <Pagination showTotal={(total) => `共${total}个文件`} total={total} onChange={(pNum,pSize) => paginationChange(pNum, pSize)} pageSize={pageSize} current={pageNum} />
                </div>
            </Modal>
            {
                uploadShow && (
                    <Modal visible={true} title="文件上传" width="50vw" onCancel={uploadModalHide} footer={null}>
                        <Upload 
                            action={uploadUrl}
                            headers={{'Authorization': `Bearer ${cookie.getCookie('user-token')}`}}
                            data={{'fileType': 'other'}}
                            defaultFileList={uploadFileList}
                            multiple={true} 
                            showUploadList={{showRemoveIcon: false, showPreviewIcon: false}}>
                            <Button type="primary" icon={<CloudUploadOutlined />} >上传</Button>
                        </Upload>
                    </Modal>
                )
            }
        </div>
    )
}

export default FileManager;