import request from '../utils/request';

export function loginAction(params){
    return request.post('admin/login', params);
}

export function getCaseList(params){
    return request.get('admin/caselist', params);
}

export function saveCaseInfo(params){
    return request.post('admin/case', params)
}

export function getUploadDatas(params){
    return request.get('admin/upload', params)
}

export function deleteUploadPic(ids){
    return request.dataDel(`admin/upload?ids=${ids}`)
}

export function deleteUploadFile(ids, type){
    return request.dataDel(`admin/upload?ids=${ids}&type=${type}`);
}

export function getCaseDetail(params){
    return request.get('admin/case', params);
}

export function deleteCase(ids){
    return request.dataDel(`admin/case?ids=${ids}`);
}

export function getFileList(params){
    return request.get('admin/filelist', params);
}

export function deleteFile(ids){
    return request.dataDel(`admin/file?ids=${ids}`)
}

export function updateFile(params){
    return request.post('admin/file', params)
}

export function getAuthList(params){
    return request.get('admin/auth', params)
}

export function saveAuth(params){
    return request.post('admin/auth', params)
}

export function deleteAuth(ids, dataType = 1){
    return request.dataDel(`admin/auth?ids=${ids}&type=${dataType}`);
}

export function temporaryAuth(params){
    return request.get('admin/temporary', params)
}

