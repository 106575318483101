import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { getCaseList, deleteCase } from '../../api';

const CaseList = (props) => {
    const navigate = useNavigate();
    const tabCols = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '特色',
            dataIndex: 'shortDesc',
            key: 'shortDesc',
        },
        {
            title: '所在地',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '添加时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '推荐度',
            dataIndex: 'recommend',
            key: 'recommend',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => {editCase(record)}}>编辑</Button>
                        <Button type='link' onClick={() => {delCase(record.id)}}>删除</Button>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        fetchCaseList(pageNum, pageSize)
    }, []);

    const fetchCaseList = (pNum, pSize) => {
        let params = {pageNum: pNum, pageSize: pSize};
        getCaseList(params).then(res => {
            if(res.code == 201){
                setTotal(res.data.total);
                setTabData(res.data.list);
            }
        })
    }

    const pageSizeChangeHandle = (val) => {
        setPageNum(1);
        setPageSize(val);
        fetchCaseList(1, val);
    }

    const pageChange = (val) => {
        setPageNum(val);
        fetchCaseList(val, pageSize);
    }

    const createNewCase = () =>{
        navigate('/cases/0');
    }

    const delCase = (caseId) => {
        deleteCase(caseId).then(res=>{
            if(res.code == 201){
                message.success('案例已删除', 3);
                fetchCaseList(pageNum, pageSize);
            }
        })
    }

    const editCase = (caseItem) => {
        navigate(`/cases/${caseItem.id}`, {state: {detail: JSON.stringify(caseItem)}})
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewCase}>新建案例</Button>
            </div>
            <div className='cnt-item'>
                <Table rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination 
                    total={total} 
                    showTotal={(total) => `共${total}个案例`}
                    current={pageNum} 
                    pageSize={pageSize} 
                    showSizeChanger={true} 
                    pageSizeOptions={[10, 20, 30, 50]}
                    onShowSizeChange={(page,size) => {pageSizeChangeHandle(size);}} 
                    onChange={(page, size) => {pageChange(page);}}
                />
            </div>
        </div>
    )
}



export default CaseList;